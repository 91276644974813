const Skills = () => (
  <div className='section-title'>
    <h3>Skills & Tools</h3>
    <p>These are the skills, tools, and technologies I <em>typically</em> use to get my projects done:</p>
    <div className='container-icons'>
      <div className='box-icon'>
        <i className='devicon-cplusplus-plain colored'></i>
        <p>C++</p>
      </div>

      <div className='box-icon'>
        <i className='devicon-typescript-plain colored'></i>
        <p>Typescript</p>
      </div>

      <div className='box-icon'>
        <i className='devicon-html5-plain colored'></i>
        <p>HTML5</p>
      </div>

      <div className='box-icon'>
        <i className='devicon-nextjs-original'></i>
        <p>Next.js</p>
      </div>

      <div className='box-icon'>
        <i className='devicon-react-original colored'></i>
        <p>React</p>
      </div>

      <div className='box-icon'>
        <i className='devicon-electron-original colored'></i>
        <p>Electron</p>
      </div>

      <div className='box-icon'>
        <i className='devicon-nodejs-plain colored'></i>
        <p>Nodejs</p>
      </div>

      <div className='box-icon'>
        <i className='devicon-redux-original colored'></i>
        <p>Redux</p>
      </div>

      <div className='box-icon'>
        <i className='devicon-git-plain colored'></i>
        <p>Git</p>
      </div>

      <div className='box-icon'>
        <i className='devicon-mongodb-plain colored'></i>
        <p>mongoDB</p>
      </div>

      <div className='box-icon'>
        <i className='devicon-vscode-plain colored'></i>
        <p>VS Code</p>
      </div>

      <div className='box-icon'>
        <i className='devicon-firebase-plain colored'></i>
        <p>Firebase</p>
      </div>

      <div className='box-icon'>
        <i className='devicon-express-original colored'></i>
        <p>Express</p>
      </div>

      <div className='box-icon'>
        <i className='devicon-graphql-plain colored'></i>
        <p>GraphQL</p>
      </div>

      <div className='box-icon'>
        <i className='devicon-mysql-plain colored'></i>
        <p>SQL</p>
      </div>

      <div className='box-icon'>
        <i className='devicon-nestjs-plain colored'></i>
        <p>Nest.js</p>
      </div>

      <div className='box-icon'>
        <i className='devicon-docker-plain colored'></i>
        <p>Docker</p>
      </div>

      <div className='box-icon'>
        <i className='devicon-jenkins-plain'></i>
        <p>Jenkins</p>
      </div>
    </div>
  </div>
);

export default Skills;
