const About = () => {
    return (
      <div className="about-title">
        <h3>About</h3>
        <div>
          <p>
            Hello👋, I am Behnam Amiri, an avid learner and a <span className='nooice'>software engineer</span> passionate
            about innovation and continuous learning. Equipped with degrees in <span className='phyTitle'>Physics</span> and
            <span className='elecTitle'> Electrical & Electronics Engineering</span>, I'm adept at tackling diverse challenges.
            In my career, I've developed a keen interest in Embedded Systems, Web Development
            and problem solving via code.
          </p>

          <p>Find some software I have written:</p>
          <ul>
            <li><a href='https://github.com/BehnamAxo'>GitHub</a></li>
            <li><a href='https://www.npmjs.com/~geodetic'>NPM</a></li>
          </ul>

          <p>Some other stuff I like to do:</p>
          <ul>
            <li>Playing <a href='https://supercell.com/en/games/clashroyale/'>Clash Royale</a></li>
            <li>Exploring new hiking trails</li>
            <li>Playing the cello</li>
            <li>Traveling to non-touristy destinations</li>
          </ul>
        </div>
      </div>
    );
  }

export default About;
