const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className='text-center'>
      <div>
        <p>© 2016-{currentYear} <span className='nooice'>Behnam Amiri</span></p>
      </div>
      <div>
        <section className='mb-2'>
          <a href='https://github.com/behnamaxo' target='_blank' rel='noreferrer'>
            <span className='contact-icon'>
              <i className='devicon-github-original'></i>
            </span>
          </a>
          <a href='https://www.linkedin.com/in/behamiri' target='_blank' rel='noreferrer'>
            <span className='contact-icon'>
              <i className='devicon-linkedin-plain'></i>
            </span>
          </a>
        </section>
      </div>
    </footer>
  );
}

export default Footer;
